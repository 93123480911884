<template>
  <v-expansion-panel
    :value="value"
    class="elevation-0 cbo-panel"
  >
    <v-expansion-panel-content 
      expand-icon="arrow_forward">
      <header slot="header">
        <span>{{ cbo.field_city }}, {{ cbo.field_state_list }}</span>
        <h4>{{cbo.title}}</h4>
      </header>
      
      <div class="cbo-panel__content">
        <div v-html="cbo.body.value"></div>

        <v-layout v-if="cbo.field_donate_link || cbo.field_stack_id">
          <v-flex xs6 v-if="cbo.field_donate_link">
            <v-btn
              outline
              block
              color="white"
              class="ml-0 px-3"
              :href="cbo.field_donate_link"
              target="_blank"
              rel="noopener"
            >
              Donate
            </v-btn>
          </v-flex>
          <v-flex xs6 v-if="cbo.field_stack_id">
            <v-btn
              outline
              block
              color="white"
              class="ml-0 px-3"
              :to="{
                name: 'make',
                params: {
                  id: cbo.field_stack_id
                }
              }"
            >
              <v-icon left dark>play_arrow</v-icon>
              Watch
            </v-btn>
          </v-flex>
        </v-layout>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'cbo-panel',

  props: {
    value: Number,
    cbo: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="stylus">
  .cbo-panel
    li
      background-color: transparent !important
    .cbo-panel__content
      padding: 16px
      padding-top: 0
      i
        margin-right: 3px
</style>