<template>
  <div class="connect">
    <view-wrapper header-text="Connect with others making a difference">
      <p>
        There are many organizations across the West who are working hard to make fire resilient forests. Learn about them and their efforts. Many have important stories to tell. Support their work by making a direct donation to each organization that resonates with you.
      </p>

      <p class="mb-5">
        Do you want to be featured on this page? <router-link :to="{ name: 'contact' }">Become a partner of ours.</router-link>
      </p>

      <v-text-field
        class="connect__filter mt-6"
        v-model="cboFilter"
        label="Search for name, city or keyword"
        solo
        clearable
      />

      <v-progress-circular 
        v-if="loading"
        :size="60"
        indeterminate>
      </v-progress-circular>

      <p v-if="!Object.keys(cbos).length && !loading"><em>No results match <strong>{{ cboFilter }}</strong></em></p>

      <v-layout wrap>
        <v-flex 
          v-for="state in Object.keys(cbos)"
          :key="state"
          class="connect__cbo-panel__flex-wrapper mb-5"
          :xs12="cbos[state].length > 1"
          :sm6="cbos[state].length <= 1">
          <h2 class="mb-0">{{ state }}</h2>

          <v-layout wrap>
            <v-flex
              :sm6="cbos[state].length > 1"
              :xs12="cbos[state].length <= 1">
              <cbo-panel
                v-for="(cbo, index) in cbos[state]"
                :key="cbo.id"
                :value="$route.hash === `#${cbo.title}` ? 0 : null"
                :data-hash="`#${cbo.title}`"
                class="mb-3"
                v-if="!(index % 2)"
                :cbo="cbo"
              />
            </v-flex>
            <v-flex :sm6="cbos[state].length > 1">
              <cbo-panel
                v-for="(cbo, index) in cbos[state]"
                :key="cbo.id"
                :value="$route.hash === `#${cbo.title}` ? 0 : null"
                :data-hash="`#${cbo.title}`"
                class="mb-3"
                v-if="index % 2"
                :cbo="cbo"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      
    </view-wrapper>
  </div>
</template>

<script>
import {
  actions as contentActions,
  getters as contentGetters
} from '@/mixins/connectors/content'
import { allCbos } from '@/api/utils/queries'
import groupBy from 'lodash.groupby'

import CboPanel from '@/components/CboPanel/CboPanel'

export default {
  name: 'connect',
  
  mixins: [contentActions, contentGetters],

  components: {
    CboPanel
  },

  async mounted() {
    try {
      const {
        hash = ''
      } = this.$route
      await this.fetchContentTypeAll({ type: this.type, filter: allCbos })

      if (hash) {
        this.$vuetify.goTo(`[data-hash="${decodeURI(hash)}"]`, {
          offset: 100
        })
      }
    } catch (e) {
      throw e
    } finally {
      this.loading = false
    }
  },

  data () {
    return {
      type: 'community_based_organization',
      loading: true,
      cboFilter: ''
    }
  },

  computed: {
    cbos() {
      return groupBy(
        this.getContentTypeByType(this.type).filter(s => {
          const filter = this.cboFilter || ''
          const city = s.field_city || ''
          const state = s.field_state_list || ''
          const title = s.title || ''
          const description = s.body && s.body.value ? s.body.value : ''
          const tags = s.field_cbo_tags && s.field_cbo_tags.length
            ? s.field_cbo_tags.toString()
            : ''

          return city.trim().toLowerCase().includes(filter.trim().toLowerCase())
            || state.trim().toLowerCase().includes(filter.trim().toLowerCase())
            || title.trim().toLowerCase().includes(filter.trim().toLowerCase())
            || description.trim().toLowerCase().includes(filter.trim().toLowerCase())
            || tags.toLowerCase().includes(filter.trim().toLowerCase())
        }),
        'field_state_list'
      )
    }
  }
}
</script>

<style lang="stylus">
  .connect
    .v-text-field .v-label
      max-width: unset !important
    .connect__filter
      max-width: 300px
    .v-expansion-panel.cbo-panel:last-child
      margin-bottom: 0 !important
</style>